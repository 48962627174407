import axios from "@/config/httpConfig";

// 根据语言和状态获取知识文章
export function findConditionKnowledgeArticle(data) {
    return axios.post("/KnowledgeArticle/findConditionKnowledgeArticle", data);
}
// 查询语言接口
export function queryLanguage(data) {
    return axios.post("/knowledgeTranslate/queryLanguage", data);
}
// 判断是否是作为主版本发布的条件
export function ismasterVersion(data) {
    return axios.post("/knowledge/ismasterVersion", data);
}
// 获取视图列表
export function GetViewList(data) {
    return axios.post('/view/list/getViewList', data)
}

// 选项卡菜单页面根据对象id获取报表列表
export function getReportListByObjId(data) {
    return axios.post('/report/getReportListByObjId', data)
}
// 看板视图展示
export function GetViewKanbanDisplay(data) {
    return axios.post('/viewKanban/display', data)
}
// 根据对象id获取图表中的仪表板列表
export function getDashboardListByObjId(data) {
    return axios.post('/dashboard/getDashboardListByObjId', data)
}
//根据记录id获取标签
export function GetTagsByRecordId(data) {
    return axios.post('/tag/getTagsByRecordId', data)
}
// 获取对象信息
export function getObjectGetObjectInfo(data) {
    return axios.post('/object/getObjectInfo', data)
}

// 获取视图列表页面信息
export function getViewListRecently(data) {
    return axios.post('/view/list/getViewListRecently', data)
}

// 保存视图字段设置
export function saveFieldSetup(data) {
    return axios.post('/view/saveFieldSetup', data)
}


// 获取视图字段设置
export function getFieldSetup(data) {
    return axios.post('/view/getFieldSetup', data)
}

// 获取视图列表表头
export function getViewListHeader(data) {
    return axios.post('/view/list/getViewListHeader', data)
}

// 获取视图列表记录
export function getViewListData(data) {
    return axios.post('/view/list/getViewListAjax', data)
}

//获取更改所有人页面信息-单条数据
export function getChangeOwner(data) {
    return axios.post('/transfer/getChangeOwner', data)
}

// 删除记录
export function del(data) {
    return axios.post('/objectdetail/delete', data)
}

//获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
}