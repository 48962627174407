/**
 * @Description: 按钮Prop实例化对象
 * @author 张亚欣
 * @date 2020年8月22日
 */
export class Button {
  constructor({ label, notShow = false, type, disabled, action, cssClass = 'small', danger, checkedLength, role, resourceCode, icon }) {
    this.label = label // 显示文字
    this.notShow = notShow // 是否显示
    this.type = type // 显示类型
    this.disabled = disabled // 是否禁用
    this.action = action // 派发事件
    this.cssClass = cssClass // 引用样式
    this.danger = danger
    this.checkedLength = checkedLength
    this.icon = icon
    this.role = role
    this.resourceCode = resourceCode
  }
}

export default function createButton ({ label, type, disabled, action, cssClass, danger, checkedLength, resourceCode, role, icon }) {
  return new Button(...arguments)
}
