<template>
  <div class="objectTableMain">
    <div class="object_box">
      <div class="pull-left topContent">
        <div class="pull-left left-content" style="padding-top: 10px">
          <input id="viewId" type="hidden" v-model="viewId" />
          <input id="ids" type="hidden" v-model="ids" />
          <!-- 切换视图 -->
          <choose-view
            class="pull-left choose-view"
            :object-name="tabName"
            :viewId="viewId"
            :view-list="viewList"
            :view-name="viewName"
            :tabStyle="tabStyle"
            @changeView="changeView"
            @getViewList="getViewList"
            :objId="objId"
            :prefix="prefix"
            :type="type"
            @refresh="refresh"
            @actives="actives"
            v-if="this.viewLength > 0"
          ></choose-view>
        </div>
        <div class="pull-right right-content">
          <button-wrapper
            class="pull-right"
            ref="wrapper"
            :selectedList="selectedList"
            :addable="addable"
            :viewId="viewId"
            :prefix="prefix"
            :tabName="tabName"
            :show-buttons="showBtns"
            :type="type"
            @add="add"
            @addTag="addTag"
            @refresh="refresh"
            @mailchimp="mailchimp"
            @change="refresh"
          ></button-wrapper>
        </div>
      </div>
      <div class="pull-left topContent">
        <div class="pull-left left-content">
          <!-- 列表表头说明文字 -->
          <statistics
            :total="totalSize"
            :isQueryCount="isQueryCount"
            :field="sortFieldName"
            :show-checked-length="type !== 'kanban'"
            :time-interval="timeInterval"
            :checked-length="
              ($refs.tablePanel && $refs.tablePanel.checkedList.length) ||
              lengths
            "
            @refresh="refresh"
          ></statistics>
        </div>

        <div
          class="pull-right right-content"
          style="padding-top: 0; width: 77%"
        >
          <!-- 视图相关操作按钮 -->
          <view-buttons
            ref="pullRight"
            class="pull-right"
            :show-buttons="showButtons"
            :isTag="isTag"
            :tab-name="tabName"
            :type="type"
            :objId="objId"
            :viewId="viewId"
            :view-name="viewName"
            :reportList="reportList"
            :loadingReport="loadingReport"
            :ismodifyFalg="ismodifyFalg"
            :prefix="prefix"
            :viewInfos="viewInfo"
            :daochuUrl="daochuUrl"
            :skippageNum="skippageNum"
            :totalSize="totalSize"
            :showScreen="showScreen"
            :isChart="isChart"
            @actives="actives"
            @getViewId="getViewId"
            @boardView="boardView"
            @planView="planView"
            @filterBoard="filterBoard"
            @multiScreen="multiScreen"
            @tagManage="tagManage"
            @chartsBoard="chartsBoard"
            @print="print"
            @reportBoard="reportBoard"
          ></view-buttons>
        </div>
      </div>
      <!-- 列表 -->
      <knowledgeindextable-panel
        class="text-center"
        ref="tablePanel"
        v-if="!showKanban && !showPlan"
        :border="true"
        :table-height="tableHeight"
        :show-index="true"
        :checked="true"
        :customHeader="true"
        :prefix="prefix"
        :obj-id="objId"
        :object-api="objectApi"
        :current-page="skippageNum"
        :view-id="viewId"
        :view-modify="ismodifyFalg"
        :pageNum="pageNum"
        :total="totalSize"
        :table-attr="tableAttr"
        :page-obj="pageObj"
        :table-button="tableBtnList"
        :noDataTip="noDataTip"
        :viewSelectedFieldList="viewSelectedFieldList"
        :viewUnselectedFieldList="viewUnselectedFieldList"
        :pict-loading="pictLoading"
        :resulterror="resulterror"
        :sortDir="sortDir"
        :sortField="sortField"
        @searchViewDetail="searchViewDetail"
        @changeSortFiled="changeSortFiled"
        @locked="locked"
        @edit="edit"
        @del="del"
        @append="append"
        @saveSelectedField="saveSelectedField"
        @onchange="onchange"
        @refresh="refresh"
        @assign="assign"
        @release="release"
        @submittranslation="submittranslation"
        @dialogAssembly="dialogAssembly"
      ></knowledgeindextable-panel>

      <!-- 看板视图 -->
      <kanban
        v-else-if="showKanban && !showPlan"
        ref="kanban"
        @chat="chat"
        @isChat="isChat"
        @longitude="longitude"
        @activeName="activeName"
        :tableHeight="tableHeight"
        :kanBanData="kanBanData"
        :selectedList="selectedList"
        :ismodifyFalg="ismodifyFalg"
      ></kanban>

      <!--      计划-排班表-->
      <shift-plan
        ref="shiftPlan"
        :viewId="viewId"
        v-else-if="!showKanban && showPlan"
      ></shift-plan>
      <!-- 删除数据提示 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="$t('label.delete')"
        top="15%"
        width="25%"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <span
          style="font-size: 16px; font-weight: bold; word-break: break-word"
        >
          <!-- 确认删除该条数据吗？ -->
          {{ delContent }}
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button
            @click="confirmDel"
            type="primary"
            size="mini"
            :loading="isBtnLoading"
          >
            <!-- 删除 -->
            {{ $t("label.delete") }}
          </el-button>
        </span>
      </el-dialog>

      <!-- 筛选器 -->
      <Filter-panel
        class="screen"
        v-if="showScreen"
        :viewInfos="viewInfo"
        :view-id="viewId"
        :objId="objId"
        :prefix="prefix"
        @viewIds="viewIds"
        ref="filterPanel"
        @filterBoard="filterBoard"
        @changeConditionValues="changeConditionValues"
        :curPushData="curPushData"
        :curHeader="curHeader"
        :style="{ height: tableHeight - 2 + 'px' }"
      ></Filter-panel>

      <!-- 标签列表 -->
      <LabelView
        class="LabelView"
        v-if="isLabelView"
        :objId="objId"
        :tabName="tabName"
        :getViewListDataParams="getViewListDataParams"
        @tagValue="tagValues"
        @gb="getMsgFormSon"
        :style="{ height: tableHeight - 2 + 'px' }"
      ></LabelView>

      <!-- 图表列表 -->
      <Chart
        v-if="isChart"
        class="chart"
        :ismodifyFalg="ismodifyFalg"
        :style="{ height: tableHeight - 2 + 'px' }"
        :view-id="viewId"
        :listDashboard="listDashboard"
        :loadingDashboard="loadingDashboard"
      ></Chart>
      <!-- 新增/修改记录 -->
      <create-edit-obj
        ref="createEditObj"
        :prefix="prefix"
        :id="id"
        :objectApi="objectApi"
        :object-name="tabName"
        :realObjId="objId"
        @save="save"
      ></create-edit-obj>
      <!-- 添加标签 -->
      <div class="add_label">
        <add-label :ids="ids" :objId="objId" ref="addlabel"></add-label>
      </div>
    </div>
    <!-- 导出至MailChimp -->
    <MailChimp
      ref="MailChimp"
      :listData="ids"
      :prefix="prefix"
      :viewId="viewId"
      :tabName="tabName"
      @MailChimpSelect="MailChimpSelect"
      :selectedList="selectedList"
      @refresh="refresh"
    ></MailChimp>
    <!-- 知识库发布 -->
    <Release
      :id="id"
      :objId="objId"
      :transfer-owner-dialog="releasedialogVisible"
      :markNewVersion="markNewVersion"
      :affectTranslation="affectTranslation"
      @releasehandleClose="releasehandleClose"
      @refreshpage="refreshpage"
    ></Release>
    <!-- 指派 -->
    <Distribution
      :id="id"
      :objId="objId"
      :transfer-owner-dialog="assigndialogVisible"
      @assignhandleClose="assignhandleClose"
      @refreshpage="refreshpage"
    >
    </Distribution>
    <!-- 提交进行翻译 -->
    <Submittranslation
      :id="id"
      :objId="objId"
      :tableData="tableData"
      :transfer-owner-dialog="submittranslationdialogVisible"
      @submittranslationhandleClose="submittranslationhandleClose"
      @refreshpage="refreshpage"
    >
    </Submittranslation>
    <!-- 删除文章、删除草稿、归档、还原、作为草稿编辑 -->
    <Dialog
      :dialogSign="dialogSign"
      :btnName="btnName"
      :titleType="titleType"
      @dialogEdit="dialogEdit"
      @dialoghandleClose="dialoghandleClose"
    >
      <template v-slot:Dialog>
        <div>
          <div v-if="btnSign == 'draftdel'">
            <!-- "您无法恢复已删除的草稿。"
           "现有草稿翻译将链接到主语言文章的上一个发布版本。如果没有发布版本，将删除任何译稿。" -->
            <div class="dialog-type">
              {{ $t("label.knowledgebase.unable.restore.draft") }}
            </div>
            <div>
              {{
                $t(
                  "label.knowledgebase.delete.translation.associated.with.draft"
                )
              }}
            </div>
          </div>
          <div v-else-if="btnSign == 'articledel'" class="dialog-type">
            <!-- "删除已归档文章会将其和所有关联版本从知识库中永久移除。" -->
            <div>
              {{ $t("label.knowledgebase.permanently.remove.articles") }}
            </div>
          </div>
          <div v-else-if="btnSign == 'file'" class="dialog-type">
            <!-- "已发布翻译也将归档，将删除处于草稿状态的翻译。" -->
            <div>{{ $t("label.knowledgebase.published.translations") }}</div>
          </div>
          <div v-else-if="btnSign == 'recovery'" class="dialog-type">
            <!-- "还原会从此版本创建草稿。您将需要发布草稿，以使其可用。" -->
            <div>{{ $t("label.knowledgebase.restore.creates.draft") }}</div>
          </div>
          <div v-else-if="btnSign == 'draftedit'" class="dialog-type">
            <!-- "已发布版本仍将在线，直到您发布此草稿。" -->
            <div>{{ $t("label.knowledgebase.published.version.online") }}</div>
          </div>
        </div>
      </template>
    </Dialog>
    <!-- 分页 -->
    <div class="block" v-if="!showKanban && !showPlan">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="skippageNum"
        :page-sizes="[10, 25, 50, 100, 200]"
        :page-size="pageSize"
        :layout="this.layout"
        :total="this.totalSize"
        :page-count="pageCount"
        :prev-text="prevText"
        :next-text="nextText"
      >
      </el-pagination>
      <span style="margin-left: 10px" v-if="isTotal">
        第 {{ skippageNum }} 页
      </span>
      <!-- 回到首页 -->
      <span class="go_homepage" v-if="isTotal" @click="goHomePage">{{
        $t("label.partnerCloud.partnerAccount.backHome")
      }}</span>
    </div>
  </div>
</template>

<script>
/**
 * 知识库
 */
import {
  ButtonWrapper,
  ViewButtons,
  ChooseView,
  knowledgeindextablePanel,
  Statistics,
  FilterPanel,
  LabelView,
  Distribution,
  Submittranslation,
  Release,
  Dialog,
} from "@/components/index";
import Chart from "@/views/commonObjects/components/chart.vue";
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
import Kanban from "@/views/commonObjects/components/kanban.vue";
import shiftPlan from "@/views/commonObjects/components/shiftPlan";
import createButton from "@/utils/Button";
import { Message } from "element-ui";
import {
  GetViewList,
  getReportListByObjId,
  getObjectGetObjectInfo,
  getDashboardListByObjId,
  GetViewKanbanDisplay,
} from "./api";
import * as CommonObjApi from "./api";
import addLabel from "@/components/LabelView/addTag.vue";
import * as types from "@/store/mutations-types";
import MailChimp from "@/components/MailChimp/index.vue";
import * as knowledgearticlesApi from "./api.js";
import DialogMixin from "@/mixin/Dialog.js";

export default {
  mixins: [TransferOwnerMixin, DialogMixin],
  components: {
    ButtonWrapper,
    ViewButtons,
    ChooseView,
    Statistics,
    FilterPanel,
    LabelView,
    Chart,
    Kanban,
    shiftPlan,
    addLabel,
    createEditObj,
    MailChimp,
    Distribution,
    Submittranslation,
    knowledgeindextablePanel, //知识库列表组件
    Release,
    Dialog,
  },
  data() {
    return {
      // 跳转项目任务需要的参数
      projectTasksObjectApi: "",
      pictLoading: false,
      lengths: 0,
      showKanban: false,
      showPlan: false,
      showScreen: false, // 是否显示筛选器
      isLabelView: false, //显示标签
      isChart: false, //显示图表
      tabName: "",
      objectApi: this.$route.params.objectApi, // 对象api
      type: this.$route.params.type, // 视图类型
      objId: this.$route.params.objId, // 对象id
      prefix: this.$route.params.prefix, // 对象前缀
      tableHeight: "",
      tableAttr: null,
      tableBtnList: {
        // 操作
        fixed: "right",
        title: this.$i18n.t("label.operate"),
        buttonList: [],
        buttonListone: [],
        buttonListtwo: [],
        transButtonList: [],
        transButtonListone: [],
        transButtonListtwo: [],
        buttonListdraft: [],
        buttonListonedraft: [],
      },
      pageObj: {
        dataList: [],
      },
      dialogVisible: false,
      showBtns: [],
      showButtons: [
        "liebiaoshitu",
        "kanbanshitu",
        "fenpingshitu",
        "shezhi",
        "baobiao",
        "daochu",
        "dayin",
        "biaoqian",
        "tubiao",
        "shaixuan",
      ],
      viewName: "",
      viewId: this.$route.query.viewId,
      viewList: [], // 视图列表
      viewLength: 0,
      pageNum: 1, //页码默认为1
      pageSize: 25, // 每页展示数据条数
      totalSize: 0, // 总记录数
      noDataTip: false,
      sortField: "", // 排序字段
      sortFieldName: "",
      sortDir: "asc", // 排序方式
      viewSelectedFieldList: [],
      viewUnselectedFieldList: [],
      searchKeyWord: "", // 搜索关键字
      conditionValues: "",
      tagIds: "", // 搜索标签
      isAllTag: "", // 是否同时满足所有标签
      isallsearch: "false",
      myself: "false",
      scrollId: "false",
      getViewListDataParams: {},
      id: "", //记录id
      timer: "", //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
      timeInterval: "",
      newViewId: "",
      labelArr: [],
      ids: "", // 表格选中数据id
      isQueryCount: false,
      viewInfo: {},
      selectedList: [], // 表格选中数据集合
      relatedItem: {}, // 事件类型的相关项
      currentUserInfo: {}, // 当前登录用户
      eventDate: {}, // 事件类型的日期
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      addable: "",
      reportList: [], // 对象对应的的报表列表
      loadingReport: false, // 报表列表的loading
      isTag: "",
      ismodifyFalg: "", //判断视图权限
      listDashboard: [], // 对象下的仪表板列表
      loadingDashboard: false, // 仪表板列表loading
      tabStyle: "", //对象图标
      kanBanData: null, //看板数据
      activeNames: null, //看板tab值
      exactSearchFlag: "", // 是否支持单字段搜索标识
      exactSearchFieldList: [], // 单字段搜索字段集合
      delContent: "", //删除提示语
      daochuUrl: "", //导出链接
      bool: null,
      isBtnLoading: false, //按钮加载中
      draftdeldialogVisible: false, //草稿删除
      articledeldialogVisible: false, //文章删除
      releasedialogVisible: false,
      filedialogVisible: false,
      recoverydialogVisible: false,
      assigndialogVisible: false,
      submittranslationdialogVisible: false,
      drafteditdialogVisible: false,
      radio: "1", //发布单选
      translateradio: "1",
      value1: "", //发布时间选择
      newchecked: true,
      draftedite: null,
      skippageNum: 1, //页码
      layout: "total, sizes, prev, pager, next, jumper",
      prevText: "", //上一页按钮文字
      nextText: "", //下一页按钮的文字
      isTotal: false, //是否显示第几页和回到首页按钮
      affectTranslation: null,
      markNewVersion: null,
      tableData: [],
      resulterror: false, //知识文章列表每行是否出现报错信息
      curPushData: [], //当前筛选器数据
      curHeader: [], //筛选器获取表头数据
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.conditionValues = ""; //切换视图时清空高级筛选条件
    this.curPushData = []; //切换视图时清空原有筛选条件
    this.viewId = to.query.viewId;
    this.type = to.params.type;
    this.objectApi = to.params.objectApi;
    this.objId = to.params.objId;
    this.prefix = to.params.prefix;
    this.totalSize = 0;
    this.pageNum = 1;
    this.skippageNum = 1;
    this.pageObj.dataList = [];
    this.sortField = "";
    this.sortFieldName = "";
    this.searchKeyWord = "";
    this.exactSearchFlag = "";
    // this.$refs.searchWrapper.exactSearchFlag = "";
    this.lastRefresh = new Date();
    this.reportList = []; // 清空报表列表
    this.listDashboard = []; // 清空仪表板列表
    // this.$refs.searchWrapper.search = "";
    if (this.$refs.tablePanel && this.$refs.tablePanel.checkedList) {
      this.$refs.tablePanel.checkedList = [];
    }
    // 判断展示表格、看板或者计划轮班视图
    if (this.type === "table") {
      this.showKanban = false;
      this.showPlan = false;
      if (this.$refs.pullRight) {
        this.$refs.pullRight.isBoardView = false;
      }
    } else if (this.type === "kanban") {
      this.showKanban = true;
      this.showPlan = false;
      this.exactSearchFlag = "0";
    } else if (this.type === "plan") {
      this.showKanban = false;
      this.showPlan = true;
    }
    // this.showKanban = this.type === 'table' ? false : true

    // 同一对象下切换列表视图和看板视图时不刷新视图列表
    if (
      to.params.prefix !== from.params.prefix ||
      (this.type === "table" && this.viewId === undefined)
    ) {
      this.getViewList(true);
    } else if (this.type === "table") {
      this.getViewListRecently();
      this.getViewListHeader();
      this.getViewListData();
      this.getObjectGetObjectInfos();
    }
    next();
  },
  created() {
    if (this.$store.state.objectList.tableListConfig) {
      let config = this.$store.state.objectList.tableListConfig;
      if (this.$route.params.prefix === config.prefix) {
        this.viewId = config.viewId; // 视图id
        this.pageSize = config.pageSize; // 每页条数
        this.skippageNum = config.skippageNum; // 当前页数
        // this.searchKeyWord = config.searchKeyWord // 当前搜索关键字
        // this.conditionValues = config.conditionValues // 当前筛选条件
        this.sortField = config.sortField; // 当前排序字段
        this.sortDir = config.sortField === "" ? "" : config.sortDir; // 当前排序顺序
        // this.exactSearchFlag = config.exactSearchFlag
      }
    }
    // 判断展示表格、看板或者计划轮班视图
    if (this.type === "table") {
      this.showKanban = false;
      this.showPlan = false;
    } else if (this.type === "kanban") {
      this.showKanban = true;
      this.showPlan = false;
    } else if (this.type === "plan") {
      this.showKanban = false;
      this.showPlan = true;
    }
    this.objId = this.$route.params.objId;
    this.prefix = this.$route.params.prefix;
    this.getViewList(true);
  },
  mounted() {
    this.$store.commit("setObjIds", this.objId);
    this.tableHeight = "100%";
    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      // 获取当前时间
      let currentTime = new Date();
      let interval = currentTime - this.lastRefresh;

      //计算出相差天数
      var days = Math.floor(interval / (24 * 3600 * 1000));
      //计算出小时数
      var leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      hours = minutes > 30 ? hours + 1 : hours;
      if (days > 0) {
        this.timeInterval =
          hours > 0
            ? `${days} ${this.$i18n.t(
                "label.login.access.day"
              )}${hours} ${this.$i18n.t("label_tabpage_hoursagoz")}`
            : `${days} ${this.$i18n.t("label_tabpage_Sdfez")}`;
      } else if (hours > 0) {
        this.timeInterval = `${hours} ${this.$i18n.t(
          "label_tabpage_hoursagoz"
        )}`;
      } else if (minutes > 0) {
        this.timeInterval = `${minutes} ${this.$i18n.t(
          "label_tabpage_minutesagoz"
        )}`;
      } else {
        this.timeInterval =
          seconds > 43
            ? this.$i18n.t("vue_label_commonobjects_view_secondsbefore", {
                seconds: seconds,
              })
            : this.$i18n.t("label.notification.time1");
      }
    }, 1000);
  },
  computed: {
    pageCount() {
      let { totalSize, pageSize } = this;
      return Math.ceil(totalSize / pageSize);
    },
  },
  methods: {
    backNumOne() {
      this.skippageNum = 1;
    },
    refreshpage() {
      this.pageNum = 1;
      this.getViewListData();
    },
    drafteditdata() {
      this.dialogSign = false;
      this.copy(this.draftedite);
    },
    // 知识库发布弹窗
    release(row) {
      this.id = row.data.id;
      let params = {
        id: this.id,
      };
      knowledgearticlesApi.ismasterVersion(params).then((res) => {
        this.releasedialogVisible = true;
        this.affectTranslation = res.data.affectTranslation;
        this.markNewVersion = res.data.markNewVersion;
      });
    },
    //知识库发布关闭弹窗
    releasehandleClose() {
      this.releasedialogVisible = false;
    },
    assign(row) {
      this.id = row.data.id;
      this.assigndialogVisible = true;
    },
    assignhandleClose() {
      this.assigndialogVisible = false;
    },
    submittranslation(row) {
      this.id = row.data.id;
      let params = {
        id: this.id,
      };
      knowledgearticlesApi.queryLanguage(params).then((res) => {
        for (let i in res.data.languageList) {
          res.data.languageList[i].data = [
            {
              id: res.data.languageList[i].default_assignee,
              name: res.data.languageList[i].assignee_name,
            },
          ];
          res.data.languageList[i].id = Number(i);
        }
        res.data.languageList.map((o) => {
          return Object.assign(o, { value: o.default_assignee });
        });
        res.data.languageList.map((item) => {
          return Object.assign(item, { ownerType: item.assignee_ownertype });
        });
        this.tableData = res.data.languageList;
      });
      this.submittranslationdialogVisible = true;
    },

    submittranslationhandleClose() {
      this.submittranslationdialogVisible = false;
    },
    getViewId(n) {
      this.newViewId = n;
      if (n) {
        this.getNewViewList();
      }
    },
    // 点击报表，在选项卡菜单页面根据对象id获取报表列表
    async reportBoard() {
      // 若是当前报表列表为空，则请求接口获取该对象下的报表列表
      if (this.reportList.length === 0) {
        this.loadingReport = true;
        let params = {
          id: this.objId, // 对象Id
        };
        let { data } = await getReportListByObjId(params);
        this.reportList = data;
        this.loadingReport = false;
      }
    },
    // 获取视图列表
    //当设置默认视图时，只刷新GetViewList，不刷新表格数据
    getViewList(viewFlag) {
      this.viewName = "";

      GetViewList({ objId: this.objId }).then((res) => {
        this.viewList = res.data;
        this.viewList.map((item) => {
          // 获取列表视图/看板视图带过来的视图信息
          if (item.id === this.viewId) {
            item.isCurrent = true;
            this.viewName = item.label;
            this.viewInfo = item;
            this.ismodifyFalg = item.ismodify;
            this.business();
          } else {
            item.isCurrent = false;
          }
        });

        if (this.viewName === "") {
          this.viewList.map((item) => {
            // 获取默认视图信息
            if (item.isdefault === "1") {
              this.viewName = item.label;
              this.viewId = item.id;
              this.viewInfo = item;
              this.ismodifyFalg = item.ismodify;
              this.business();
            } else {
              item.isCurrent = false;
            }
          });
        }

        // 若接口返回视图列表无默认视图，将第一个视图作为显示视图
        if (this.viewName === "") {
          this.viewList[0].isCurrent = true;
          this.viewName = this.viewList[0].label;
          this.viewId = this.viewList[0].id;
          this.viewInfo = this.viewList[0];
          this.ismodifyFalg = this.viewList[0].ismodify;
          this.business();
        }
        this.viewLength = this.viewList.length;
        if (viewFlag) {
          this.getViewListRecently();
          this.getViewListHeader();
          this.getViewListData();
          this.getObjectGetObjectInfos();
        }
     
      });
    },
    /**
     * 获取视图列表
     * 新建视图保存后刷新视图列表，将新建视图设置为当前视图
     */
    // 获取视图列表
    getNewViewList() {
      this.viewName = "";

      GetViewList({
        objId: this.objId,
      }).then((res) => {
        this.viewList = res.data;
        this.viewList.map((item) => {
          // 获取当前视图信息
          if (item.id === this.newViewId) {
            item.isCurrent = true;
            this.viewName = item.label;
            this.viewInfo = item;
            this.ismodifyFalg = item.ismodify;
          } else {
            item.isCurrent = false;
          }
        });
        if (this.viewName === "") {
          this.viewList.map((item) => {
            // 获取默认视图信息
            if (item.isdefault === "1") {
              item.isCurrent = true;
              this.viewName = item.label;
              this.viewId = item.id;
              this.ismodifyFalg = item.ismodify;
              this.viewInfo = item;
            } else {
              item.isCurrent = false;
            }
          });
        } else {
          this.viewId = this.newViewId;
        }

        // 若接口返回视图列表无默认视图，将第一个视图作为显示视图
        if (this.viewName === "") {
          this.viewList[0].isCurrent = true;
          this.viewName = this.viewList[0].label;
          this.viewId = this.viewList[0].id;
          this.viewInfo = this.viewList[0];
          this.ismodifyFalg = this.viewList[0].ismodify;
        }

        this.viewLength = this.viewList.length;
        this.pageNum = 1;
        this.skippageNum = 1;
        this.getViewListRecently();
        this.getViewListHeader();
        this.getViewListData();
        this.changeView(this.viewInfo);
        this.getObjectGetObjectInfos();
      });
    },
    // 获取视图列表按钮权限
    getViewListRecently() {
      this.exactSearchFlag = "";
      this.exactSearchFieldList = [];

      CommonObjApi.getViewListRecently({
        obj: this.prefix,
        viewId: this.viewId,
      }).then((res) => {
        document.title = this.$setTitle(
          `${res.data.viewName} | ${res.data.label}`
        );
        this.tabName = res.data.label;
        this.tabStyle = res.data.tabStyle ? res.data.tabStyle : "";
        this.exactSearchFlag = res.data.exactSearchFlag || "";
        this.exactSearchFlag =
          this.type === "kanban" ? "0" : this.exactSearchFlag;
        this.exactSearchFieldList = res.data.exactSearchFieldList || [];
        this.$store.commit(types.SET_TAB_NAME, this.tabName);

        if (this.prefix === "k02") {
          // 主版本已发布
          this.tableBtnList.buttonList = [
            // 操作
            createButton({
              // 作为草稿编辑
              label: this.$i18n.t("label.knowledgebase.edit.as.draft"),
              action: "draftedit",
            }),
            createButton({
              // 归档
              label: this.$i18n.t("label.group.archived"),
              action: "file",
            }),
            createButton({
              // 提交进行翻译
              label: this.$i18n.t("label.knowledgebase.submit.for.translation"),
              action: "submittranslation",
            }),
          ];
          // 主版本已发布下有草稿
          this.tableBtnList.buttonListdraft = [
            createButton({
              // 归档
              label: this.$i18n.t("label.group.archived"),
              action: "file",
            }),
            createButton({
              // 提交进行翻译
              label: this.$i18n.t("label.knowledgebase.submit.for.translation"),
              action: "submittranslation",
            }),
          ];
          // 主版本已归档
          this.tableBtnList.buttonListone = [
            // 已归档
            createButton({
              // 删除文章
              label: this.$i18n.t("label.knowledgebase.delete.article"),
              action: "articledel",
            }),
            createButton({
              // 还原
              label: "还原",
              action: "recovery",
            }),
          ];
          // 主版本已归档下有草稿
          this.tableBtnList.buttonListonedraft = [
            createButton({
              // 还原
              label: "还原",
              action: "recovery",
            }),
          ];
          // 主版本草稿
          this.tableBtnList.buttonListtwo = [
            createButton({
              // 编辑
              label: this.$i18n.t("label.modify"),
              action: "edit",
            }),
            createButton({
              // 指派
              label: this.$i18n.t("label.knowledgebase.assign"),
              action: "assign",
            }),
            createButton({
              // 发布
              label: this.$i18n.t("pagecreator.button.publish"),
              action: "release",
            }),
            createButton({
              // 删除草稿
              label: this.$i18n.t("label.knowledgebase.delete.draft.t"),
              action: "draftdel",
            }),
            createButton({
              // 提交进行翻译
              label: this.$i18n.t("label.knowledgebase.submit.for.translation"),
              action: "submittranslation",
            }),
          ];

          // 翻译版本草稿
          this.tableBtnList.transButtonList = [
            createButton({
              // 编辑
              label: this.$i18n.t("label.modify"),
              action: "edit",
            }),
            createButton({
              // 发布
              label: this.$i18n.t("pagecreator.button.publish"),
              action: "release",
            }),
            createButton({
              // 删除草稿
              label: this.$i18n.t("label.knowledgebase.delete.draft.t"),
              action: "draftdel",
            }),
            createButton({
              // 指派
              label: this.$i18n.t("label.knowledgebase.assign"),
              action: "assign",
            }),
          ];
          // 翻译版本发布
          this.tableBtnList.transButtonListone = [
            createButton({
              // 作为草稿编辑
              label: this.$i18n.t("label.knowledgebase.edit.as.draft"),
              action: "draftedit",
            }),
          ];
          // 翻译版本归档
          this.tableBtnList.transButtonListtwo = [
            createButton({
              // 无操作可用
              label: this.$i18n.t("label.knowledgebase.no.action.available"),
              action: "",
            }),
          ];
        } else {
          this.tableBtnList.buttonList.splice(
            2,
            1,
            //转移所有人
            createButton({
              label: this.$i18n.t("label_tabpage_cownerz"),
              action: "transferOwner",
            })
          );
        }
        // 预计人工成本、实际人工成本不展示操作列
        if (
          this.$cookies.get("activeTabObjId") === "cloudccPlanCost" ||
          this.$cookies.get("activeTabObjId") === "cloudccRealCost"
        ) {
          this.tableBtnList.buttonList = [];
        }
        // 是否启用查找统计
        this.isQueryCount = res.data.isQueryCount;
        this.showBtns = res.data.listBtn.concat(res.data.toolsBtn);
      });
    },
    searchViewDetail() {
      if (
        (this.viewId === "0" ||
          this.viewId === "1" ||
          this.viewId === "2" ||
          this.viewId === "3") &&
        this.ismodifyFalg !== "true"
      ) {
        return
      } else {
        this.getFieldSetup();
      }
    },
    // 保存视图选中字段
    saveSelectedField(ids) {
      CommonObjApi.saveFieldSetup({
        objId: this.objId,
        viewId: this.viewId,
        fieldIds: ids,
      }).then(() => {
        this.pageNum = 1;
        this.skippageNum = 1;
        this.getViewListHeader();
        this.getViewListData();
        this.$message.success(this.$i18n.t("label.search.saveok"));
      });
    },
    // 获取视图详细信息
    getFieldSetup() {
      this.viewSelectedFieldList = [];
      this.viewUnselectedFieldList = [];
      CommonObjApi.getFieldSetup({
        objId: this.objId,
        viewId: this.viewId,
      }).then((res) => {
        this.viewSelectedFieldList = res.data.viewSelectedFieldList;
        this.viewUnselectedFieldList = res.data.viewUnselectedFieldList;
      });
    },
    // 获取视图列表表头
    getViewListHeader() {
      this.showScreen = false;
      this.isLabelView = false;
      this.isChart = false;
      this.pictLoading = true;
      this.tableAttr = [];
      let params = {
        obj: this.prefix,
        viewId: this.viewId,
        tagIds: this.tagIds,
        isAllTag: this.isAllTag,
        searchKeyWord: this.searchKeyWord,
        conditionValues: this.conditionValues,
      };

      CommonObjApi.getViewListHeader(params).then((res) => {
        this.curHeader = res; //高级筛选器传参
        // 打印数据存储到vuex
        this.$nextTick(() => {
          //解决第一次触发emit无效问题
          this.$store.state.printHeard = this.tableAttr;
        });
        if (
          this.objId === "cloudccPlanCost" ||
          this.objId === "cloudccRealCost"
        ) {
          //项目管理中（实际人工成本和预计人工成本）隐藏新建按钮
          this.addable = false;
        } else {
          this.addable = res.data.objServices.add;
        }
        this.tableAttr = res.data.headLabel;
        this.tableAttr.forEach((item, idx) => {
          // 名称、查找、主详、查找多选均可跳转
          // 事件及任务下的主题、名称、相关项可跳转
          if (
            item.schemefieldName === "name" ||
            item.schemefieldType === "Y" ||
            item.schemefieldType === "M" ||
            item.schemefieldType === "MR" ||
            item.schemefieldName === "subject" ||
            item.lookupObj === "user" ||
            item.schemefieldName === "whoid" ||
            item.schemefieldName === "relateid"
          ) {
            this.$set(this.tableAttr[idx], "click", "detail");
          }

          // 项目管理-实际工作清单点击日期跳转详情
          if (this.$cookies.get("activeTabObjId") === "projectworklist") {
            if (item.schemefieldType === "D") {
              this.$set(this.tableAttr[idx], "click", "detail");
            }
          }
          this.$set(this.tableAttr[idx], "sortByThis", false);
          this.$set(this.tableAttr[idx], "sortDir", "asc");
          this.$set(this.tableAttr[idx], "fixed", false);
          this.$set(this.tableAttr[idx], "locked", false);
        });

        this.$refs.tablePanel &&
          this.$refs.tablePanel.changeTableAttr(this.tableAttr);
        setTimeout(() => {
          this.pictLoading = false;
        }, 1000);
      });
    },
    // 刷新
    refresh(id) {
      this.$refs.kanban && this.$refs.kanban.GetViewKanbanDisplays("", id);
      this.sortField = "";
      this.sortFieldName = "";
      this.lastRefresh = new Date();
      // 重置列表信息
      this.pageObj.dataList = [];
      this.pageNum = 1;
      this.skippageNum = 1;
      // 重置列表选中统计数据
      if (this.$refs.tablePanel) {
        this.$refs.tablePanel.checkedList = [];
      }
      this.getViewListHeader();
      this.getViewListData();
    },
    // 获取视图数据
    getViewListData() {
      if (this.viewId) {
        /**
         * 参数说明
         * 参数名	必选	类型	说明
         * obj	是	string	对象前缀
         * viewId	是	string	视图Id
         * pageNum	是	string	页数
         * pageSize	是	string	每页数量
         * sortField	是	string	排序字段apiname
         * sortDir	是	string	排序类型(desc[降序]/asc[升序])
         * searchKeyWord	是	string	搜索关键字
         * conditionValues	是	string	高级搜索条件
         * ischangenum	是	string	是否修改每页查询数目（修改的数目为视图默认每页数量）
         * recordnums	是	string	每页查询默认数目
         * tagIds	否	string	标签id，多个标签逗号分隔，按选中标签查询
         * isAllTag	否	string	是否同时满足所有选中标签（true/false）
         * campaignid	否	string	市场活动成员是否只查询现有成员
         * isallsearch	否	string	是否启用启用全局搜索，conditionValues不用空也不启用全局搜索
         * myself	否	string	全局搜索是否只查询所有人是自己的记录
         * scrollId	否	string	全文检索查询返回值，下一页查询回传
         * type	否	string	contact/lead特殊查询使用以不使用全文检索，参数暂时不用
         */

        this.noDataTip = false;
        this.pictLoading = true;
        let tableListConfig = {
          prefix: this.prefix, // 视图id
          viewId: this.viewId, // 视图id
          pageSize: this.pageSize, // 每页条数
          skippageNum: this.skippageNum, // 当前页数
          sortField: this.sortField, // 当前排序字段
          sortDir: this.sortField === "" ? "" : this.sortDir, // 当前排序顺序
        };
        this.$store.commit("setTableListConfig", tableListConfig); // 存储列表条件

        this.getViewListDataParams = {
          obj: this.prefix,
          viewId: this.viewId,
          pageNum: this.skippageNum, //Y  分页页码
          pageSize: this.pageSize,
          sortField: this.sortField,
          sortDir: this.sortField === "" ? "" : this.sortDir,
          searchKeyWord: this.searchKeyWord,
          conditionValues: this.conditionValues,
          ischangenum: "false",
          recordnums: this.pageSize,
          tagIds: this.tagIds,
          isAllTag: this.isAllTag,
          campaignid: "false",
          isallsearch: this.isallsearch,
          myself: this.myself,
          scrollId: this.scrollId,
          type: "",
          exactSearchFlag: this.exactSearchFlag,
        };
        CommonObjApi.getViewListData(this.getViewListDataParams).then((res) => {
          this.daochuUrl = res.data.exportUrl;
          if (res.result && res.returnCode === "1") {
            // 请求第一页数据时先清空列表
            if (this.getViewListDataParams.pageNum === 1) {
              this.pageObj.dataList = [];
            }
            /**
             * 分页
             */
            if (res.data.list.length === 0 && this.pageNum === 1) {
              this.pageObj.dataList = [];
              this.pageNum++;
            } else {
              let checkedList = this.$refs.tablePanel.getCheckedList();
              this.pageObj.dataList = res.data.list;
              var reserr = this.pageObj.dataList.find((v) => {
                return v.errInfo
              });
              if (reserr !== undefined) {
                this.resulterror = true;
              } else {
                this.resulterror = false;
              }
              this.pageObj.dataList.forEach((element) => {
                checkedList.forEach((item) => {
                  if (item.id === element.id) {
                    element.checked = true;
                  }
                });
              });

              /**
               * 切换页码或者 *条/页 的时候表格选中数据清除
               */
              if (this.$refs.tablePanel) {
                this.$refs.tablePanel.checkedList = [];
              }
              this.pageNum++;
              this.$refs.tablePanel.dataList = this.pageObj.dataList;
            }

            // 判断是否有总数
            if (res.data.isQueryCount === "true") {
              this.layout = "total, sizes, prev, pager, next, jumper";
              this.totalSize = res.data.totalSize;
              this.isTotal = false;
            } else {
              this.layout = "sizes, prev, next";
              this.isTotal = true;
              if (res.data.haveMore === "true") {
                this.totalSize = (this.skippageNum + 1) * this.pageSize;
              }
            }
            setTimeout(() => {
              this.pictLoading = false;
            }, 1000);
            setTimeout(() => {
              this.$refs.tablePanel &&
                this.$refs.tablePanel.$refs.tablePanel &&
                this.$refs.tablePanel.$refs.tablePanel.doLayout();
            }, 2000);
            this.lastRefresh = new Date();
            this.pageObj.totalSize = res.data.totalSize;
          } else {
            this.$message.error(res.returnInfo);
          }
        });
      }
    },
    // 切换视图
    changeView(view) {
      this.exactSearchFlag = "";
      this.exactSearchFieldList = [];
      this.conditionValues = ""; //切换视图时清空高级筛选条件
      this.conditionValues = ""; //切换视图时清空高级筛选条件
      this.curPushData = []; //切换视图时清空原有筛选条件
      if (this.$route.params.objectApi === "Opportunity") {
        this.showButtons = [
          "liebiaoshitu",
          "kanbanshitu",
          "fenpingshitu",
          "shezhi",
          "baobiao",
          "daochu",
          "dayin",
          "baobiao",
          "biaoqian",
          "tubiao",
          "shaixuan",
          "xiaoshou",
        ];
      } else {
        this.showButtons = [
          "liebiaoshitu",
          "kanbanshitu",
          "fenpingshitu",
          "shezhi",
          "baobiao",
          "daochu",
          "dayin",
          "biaoqian",
          "tubiao",
          "shaixuan",
        ];
      }
      this.viewList.map((item) => {
        // 获取默认视图信息
        if (item.id === view.id) {
          this.viewName = item.label;
          this.viewId = item.id;
          item.isCurrent = true;
          this.ismodifyFalg = item.ismodify;
          this.viewInfo = item;
          this.business();
        } else {
          item.isCurrent = false;
        }
      });

      if (this.type === "plan") {
        this.$refs.shiftPlan.handleView();
      } 
      // 刷新视图列表
      // this.viewId=view.id
      this.refresh(view.id);
      this.getViewListRecently();
      this.getObjectGetObjectInfos();
    },
    // 新建
    add() {
      this.id = "";
      this.$refs.createEditObj.add();
    },
    edit(row, type = "") {
      this.id = row.data.id;
      this.$nextTick(() => {
        this.$refs.createEditObj.edit(row, type);
      });
    },
    // 新增/修改保存
    save() {
      this.pageNum = 1;
      this.getViewListData();
    },
    // 删除
    del(row) {
      //项目管理中项目任务删除子任务时修改objectApi
      if (
        this.$route.path.substring(this.$route.path.length - 3) == "p03" &&
        row.data.their_task
      ) {
        this.objectApi = "cloudccSubtask";
      }
      if (this.objectApi === "CloudccProject") {
        //删除该项目，将删除项目和项目关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t("label.projectManagement.delete.item");
      } else if (this.objectApi === "cloudccMilestone") {
        // 删除该里程碑，将删除里程碑和里程碑关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t(
          "label.projectManagement.delete.milestone"
        );
      } else if (this.objectApi === "CloudccProblem") {
        //删除该问题，将删除问题和问题关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t(
          "label.projectManagement.delete.question"
        );
      } else if (this.objectApi === "cloudccTask") {
        // '删除该任务，将删除任务和任务关联的所有信息，且数据无法恢复，是否确认删除？'
        this.delContent = this.$i18n.t("label.projectManagement.delete.task");
      } else if (this.objectApi === "cloudccSubtask") {
        // 删除该子任务，将删除子任务和子任务关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t(
          "label.projectManagement.delete.subtasks"
        );
      } else {
        this.delContent = this.$i18n.t("label.weixin.confirm.delete");
      }
      this.id = row.data.id;
      this.dialogVisible = true;
    },
    // 确认删除数据
    confirmDel() {
      this.isBtnLoading = true;
      CommonObjApi.del({ id: this.id, objectApi: this.objectApi })
        .then(() => {
          this.isBtnLoading = false;
          this.pageNum = 1;
          this.dialogVisible = false;
          this.$message.success(this.$i18n.t("label.ems.delete.success"));
          this.getViewListData();
        })
        .catch(() => {
          this.isBtnLoading = false;
        });
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 看板视图
    boardView() {
      if (this.$route.path.indexOf("/commonObjects/views/kanban") === -1) {
        this.$router.push({
          path: `/commonObjects/views/kanban/${this.objectApi}/${this.objId}/${this.prefix}`,
          query: {
            viewId: this.viewId,
          },
        });
      }
    },
    // 轮班计划视图
    planView() {
      if (this.$route.path.indexOf("/commonObjects/views/plan") === -1) {
        this.$router.push({
          path: `/commonObjects/views/plan/${this.objectApi}/${this.objId}/${this.prefix}`,
          query: {
            viewId: this.viewId,
          },
        });
        this.$nextTick(() => {
          this.$refs.shiftPlan.renderPage();
        });
      }
    },
    // 分屏视图
    multiScreen() {
      this.$router.push({
        path: `/commonObjects/multi-screen/${this.objectApi}/${this.objId}/${this.prefix}`,
        query: {
          sortFieldName: this.sortFieldName,
          sortField: this.sortField,
          sortDir: this.sortDir,
          viewId: this.viewId,
        },
      });
    },
    // 锁定
    locked(field) {
      let fiexdList = this.tableAttr.filter((item) => {
        return item.fixed === true;
      });

      if (fiexdList.length > 2) {
        Message.warning(
          this.$i18n.t("vue_label_commonobjects_view_fixed_at_most")
        );
        this.tableAttr.forEach((item) => {
          if (item.id === field.id) {
            item.fixed = false;
            item.locked = false;
          }
        });
      } else {
        this.tableAttr.forEach((item) => {
          if (item.id === field.id) {
            item.fixed = !item.fixed;
          }
        });
      }

      this.$forceUpdate();
      this.$refs.tablePanel.changeTableAttr(this.tableAttr);
    },
    // 修改排序字段
    changeSortFiled(field) {
      this.pageNum = 1;
      this.skippageNum = 1;
      this.pageObj.dataList = [];

      // 若排序字段不变，改变排序方式
      if (this.sortField !== field.apiname) {
        // 否则重置排序字段和排序方式
        this.clearSort();
        this.sortDir = "asc";
        this.sortField = field.apiname;
        this.sortFieldName = field.nameLabel;
      } 
      // 刷新视图
      this.getViewListData();
      this.$forceUpdate();
      this.$refs.tablePanel.changeTableAttr(this.tableAttr);
    },
    // 清除排序
    clearSort() {
      this.tableAttr.forEach((item) => {
        item.sortByThis = false;
      });
    },
    // 滚动加载数据
    append() {
      if (!this.noDataTip) {
        this.getViewListData();
      }
    },
    // 显示页数发生变化时
    handleSizeChange(val) {
      this.pageSize = val;
      this.getViewListData();
    },
    // 当前页码发生变化时
    handleCurrentChange(val) {
      this.skippageNum = val;
      this.getViewListData();
      // this.$emit("refresh");
    },
    //点击回到首页按钮
    goHomePage() {
      this.skippageNum = 1;
      this.getViewListData();
    },
    // 筛选器：点击按钮及关闭的回调
    filterBoard() {
      this.showScreen = !this.showScreen;
      this.isLabelView = false;
      this.isChart = false;
    },
    // 高级筛选器修改changeConditionValues传参
    changeConditionValues(val, curData) {
      this.conditionValues = val;
      this.curPushData = curData;
      if (
        this.viewId === "0" ||
        this.viewId === "1" ||
        this.viewId === "2" ||
        this.viewId === "3"
      ) {
        this.getViewListHeader();
      }
      this.showScreen = true;
      this.getViewListData();
    },
    //标签列表
    tagManage() {
      this.isLabelView = !this.isLabelView;
      this.isChart = false;
      this.showScreen = false;
    },
    //图表列表,根据对象id获取图表内的仪表板列表
    async chartsBoard() {
      this.isChart = !this.isChart;
      this.showScreen = false;
      this.isLabelView = false;
      // 判断是否在点击图表按钮展开
      if (this.isChart) {
        // 判断仪表板列表是否有数据
        if (this.listDashboard.length === 0) {
          this.loadingDashboard = true;
          let params = {
            objId: this.objId, // 对象id
          };
          let data = await getDashboardListByObjId(params);
          if (data.result) {
            this.listDashboard = data.data.listDashboard;
          } else {
            this.listDashboard = [];
          }
          this.loadingDashboard = false;
        }
      }
    },
    //打印
    print() {
      window.open(
        `#/print-table/${this.objId}/${this.prefix}/${this.objectApi}/${this.viewId}?tagIds=${this.tagIds}`,
        "_parent - URL",
        "height=700, width=1210, top=50, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
      );
    },
    getMsgFormSon(data) {
      this.isLabelView = data;
    },

    //标签页向父组件传值
    tagValues(tagValue,allTag) {
      this.isAllTag=allTag;
      this.tagIds = tagValue;
      this.skippageNum = 1;
      this.getViewListData();
    },
    actives() {
      this.$refs.kanban.open();
    },
    addTag() {
      if (this.selectedList.length > 200 || this.lengths > 200) {
        this.$message.info(
          this.$i18n.t("vue_label_commonobjects_view_up_to_data")
        );
      } else {
        if (this.ids === "") {
          this.$message.info(this.$i18n.t("lable.opppdt.selPdt.leastone"));
        } else {
          this.$refs.addlabel.isShows();
        }
      }
    },
    // 表格选中数据
    onchange(val) {
      this.selectedList = val;

      if (this.bool == false) {
        this.selectedList = this.$store.state.checkedNum;
      }
      let ids = "";
      for (var i = 0; i < val.length; i++) {
        ids += val[i].id + ",";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1);
      }
      this.ids = ids;
    },
    chat(val) {
      this.ids = val;
    },
    isChat(val) {
      if (val === true) {
        this.showButtons = [
          "liebiaoshitu",
          "kanbanshitu",
          "fenpingshitu",
          "shezhi",
          "baobiao",
          "daochu",
          "dayin",
          "tubiao",
          "shaixuan",
        ];
      } else {
        if (this.viewInfo.ismodify === "false") {
          this.showButtons = [
            "liebiaoshitu",
            "fenpingshitu",
            "shezhi",
            "baobiao",
            "daochu",
            "dayin",
            "biaoqian",
          ];
        } else {
          this.showButtons = [
            "liebiaoshitu",
            "kanbanshitu",
            "fenpingshitu",
            "shezhi",
            "baobiao",
            "daochu",
            "dayin",
            "biaoqian",
            "tubiao",
            "shaixuan",
          ];
        }
      }
    },
    longitude(val) {
      this.lengths = val;
    },
    //判断按钮权限
    business() {
      // 先对按钮集合赋初始值
      this.showButtons = [
        "liebiaoshitu",
        "fenpingshitu",
        "shezhi",
        "baobiao",
        "daochu",
        "dayin",
      ];
      if (this.$route.params.objectApi === "Opportunity") {
        // 业务机会对象，可展示【销售漏斗】
        this.showButtons.push("xiaoshou");
      }
      if (
        this.$route.params.type === "table" ||
        this.$route.params.objectApi === "CloudccKArticle"
      ) {
        // 对象已启用且属于列表视图，可展示【标签】
        this.showButtons.push("biaoqian", "shaixuan");
      }
      if (
        !(
          this.viewInfo.id === "0" ||
          this.viewInfo.id === "1" ||
          this.viewInfo.id === "2" ||
          this.viewInfo.id === "3"
        )
      ) {
        // 非四个特殊视图，可展示【看板视图】、【图表】
        this.showButtons.push("kanbanshitu", "tubiao");
      }
    },
    viewIds(val) {
      this.newViewId = val;
      this.getNewViewList();
    },
    async getObjectGetObjectInfos() {
      let params = {
        id: this.objId,
      };
      let res = await getObjectGetObjectInfo(params);
      if (res.result) {
        this.isTag = res.data.istag;
      }
    },
    //获取看板视图展示数据
    async GetViewKanbanDisplays(val) {
      let params = {
        viewId: this.viewId,
        searchKeyWord: val,
        recordType: this.activeNames,
      };
      let res = await GetViewKanbanDisplay(params);
      if (res.result) {
        this.kanBanData = res.data;
      }
    },
    //看板tab值
    activeName(val) {
      this.activeNames = val;
    },
    //打开MailChimp弹框
    mailchimp() {
      if (this.selectedList.length === 0) {
        this.$message.error(
          this.$i18n.t("label.integrations.mailchimp.massage1")
        );
      } else {
        this.$refs.MailChimp.open();
      }
    },
    //全选列表数据
    MailChimpSelect(val) {
      this.bool = val;
      if (val === true) {
        this.$refs.tablePanel.$refs.tablePanel.toggleAllSelection();
      } else {
        this.$refs.tablePanel.$refs.tablePanel.clearSelection();
        this.selectedList = this.$store.state.checkedNum;
        this.$store.state.checkedNum.forEach((item) => {
          this.$refs.tablePanel.$refs.tablePanel.toggleRowSelection(item, true);
        });
      }
    },
  },
  watch: {
    type(val) {
      if (val === "table") {
        this.lengths = 0;
        this.isChat(false);
      } else if (val === "Opportunity") {
        this.showButtons = [
          "liebiaoshitu",
          "kanbanshitu",
          "fenpingshitu",
          "shezhi",
          "baobiao",
          "daochu",
          "dayin",
          "biaoqian",
          "tubiao",
          "shaixuan",
          "xiaoshou",
        ];
      }
    },
    tabName() {
      // 列表表头日历更改为事件
      if (this.objectApi === "Event") {
        this.tabName = this.$i18n.t("label.event");
      }
    },
    // 获取路由
    $route: {
      handler: function (route) {
        // 获取到项目任务页面对象api
        this.projectTasksObjectApi = route.params.objectApi;
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    localStorage.setItem("relevantObjectLevel", 0);
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table--border {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

::v-deep .el-table::before {
  height: 0;
}

::v-deep .el-table__fixed::before {
  height: 0;
}

.groupTitle {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  background: #f0f0f0;
}

.objectTableMain {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;
  .object_box {
    overflow: hidden;
    border-radius: 3px;
    border: 1px solid #dedcda;
    height: calc(100% - 50px);
    background: #f5f5f5;
    //  display: flex;
    // flex-direction: column;
  }
}

/* 筛选器位置 */
.screen {
  // height: 88%;
  position: absolute;
  right: 11px;
  top: 109px;
  z-index: 99;
}

/* 标签列表位置 */
.LabelView {
  // height: 88%;
  position: absolute;
  right: 11px;
  top: 111px;
  z-index: 99;
}

/* 图表位置 */
.chart {
  width: 300px;
  // height: 88%;
  position: absolute;
  right: 11px;
  top: 109px;
  z-index: 99;
}

.topContent {
  width: 100%;
  // background: #fff;
}

.left-content {
  width: 23%;
  padding: 10px 10px 0;

  .choose-view {
    width: 100%;
  }
}

.right-content {
  width: 77%;
  padding: 13px 10px 0 0;

  .searchWrapper {
    margin-right: 10px;
    height: 41px;
  }
}

.add_label {
  position: absolute;
  top: 57px;
  left: 35%;
}
.selectnum {
  height: 12px;
  font-size: 12px;
  margin-top: -20px;

  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;

  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
/*批量新增css*/
.tank {
  height: 480px;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}

.listulFir {
  height: 40px;
  min-width: 100%;
  border: 1px solid rgba(190, 225, 255, 1);
  border-left: none;
  display: inline-flex;
  background: rgba(222, 240, 253, 1);
}
.listulSec:nth-of-type(2n) {
  height: 40px;
  display: inline-flex;
  background: rgba(240, 248, 255, 1);
  min-width: 100%;
}
.listulSec:nth-of-type(2n + 1) {
  height: 40px;
  display: inline-flex;
  background: white;
  min-width: 100%;
}
.firstLi {
  border-right: 1px solid rgba(190, 225, 255, 1);
  min-width: 80px;
  text-align: center;
}
.secondLi {
  border-right: 1px solid rgba(190, 225, 255, 1);
  text-align: center;
  width: 180px;
  line-height: 40px;
}
::v-deep .el-input {
  height: 30px;
  width: 100%;
  text-align: center;
}
::v-deep .el-input__inner {
  height: 30px;
  width: 100%;
}
::v-deep .el-button--mini {
  padding: 7px 7px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  max-height: 250px;
  border: 1px solid rgba(190, 225, 255, 1);
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
.tianjia {
  margin-top: 20px;
  cursor: pointer;
  width: 32px;
  margin-bottom: 10px;
  margin-left: 16px;
}
ul {
  margin-bottom: 0;
}
.block {
  background: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 9;
}
.dialog-type {
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
}
</style>
