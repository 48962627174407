/*
 * @Author: your name
 * @Date: 2022-01-12 16:45:29
 * @LastEditTime: 2022-02-07 16:25:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\mixin\konwelegeDialg.js
 */
import * as knowledgearticlesApi from "./api";
export default {
  data() {
    return {
      content: [],//删除文章、删除草稿、归档内容
      dialogSign: false,//删除文章、删除草稿、归档弹窗
      btnName: "",//删除文章、删除草稿、归档弹窗按钮显示类型
      titleType: "",//删除文章、删除草稿、归档弹窗标题显示类型
      // id: "",
      draftedite: null,
      btnSign: ""//按钮标识
    }
  },
  methods: {
    // 关闭弹窗
    dialoghandleClose() {
      this.dialogSign = false
    },
    // 文章删除、草稿删除、还原、作为草稿编辑、归档弹窗
    dialogAssembly(id, type) {
      this.draftedite = id;
      if (Object.prototype.toString.call(id) === '[object String]') {
        this.id = id;
      } else {
        if (id.data) {
          this.id = id.data.id;
        } else {
          this.id = id.id;
        }
      }
      this.content = []
      this.dialogSign = true;
      this.btnSign = type
      if (type == "articledel") {
        //  "将文章从归档中移除？"
        this.titleType = this.$t("label.knowledgebase.remove.article");
        // "删除"
        this.btnName = this.$t("component.chatter.button.delete")
      } else if (type == "draftdel") {
        //  "删除草稿？"
        this.titleType = this.$t("label.knowledgebase.delete.draft");
        // "删除"
        this.btnName = this.$t("component.chatter.button.delete")
      } else if (type == "file") {
        //  "归档一篇文章？"
        this.titleType = this.$t("label.knowledgebase.archive.an.article");
        // "归档"
        this.btnName = this.$t("label.group.archived")
      } else if (type == "recovery") {
        //  "还原文章？"
        this.titleType = this.$t("label.knowledgebase.restore.article");
        // "还原"
        this.btnName = this.$t("label.knowledgebase.reduction")
      } else {
        //  "编辑草稿？"
        this.titleType = this.$t("label.knowledgebase.edit.draft");
        // "作为草稿编辑"
        this.btnName = this.$t("label.knowledgebase.edit.as.draft")
      }
    },
    dialogEdit() {
      if (this.btnSign == "draftdel") {
        //删除草稿
        this.removeDraft();
      } else if (this.btnSign == "articledel") {
        //删除文章
        this.articlesure();
      } else if (this.btnSign == "file") {
        //归档
        this.filesure();
      } else if (this.btnSign == "recovery") {
        //还原
        this.recoverArchived();
      } else {
        //作为草稿编辑  
        this.evalfun(this.drafteditdata)
        this.evalfun(this.copy)
      }
      this.dialoghandleClose();
    },
    //判断函数是否存在
    evalfun(funName) {
      try {
        if (typeof (eval(funName)) == "function") {
          funName();
        }
      } catch (e) {
      }
    },
    // 删除草稿
    removeDraft() {
      if (this.id) {
        let params = {
          objectApi: "CloudccKArticle",
          id: this.id,
          isConfirmDel: "true",
        };
        knowledgearticlesApi.removeDraft(params).then((res) => {
          if (this.jumpsign) {
            this.$router.push({
              path: `/Knowledgearticles/CloudccKArticle/cloudcc_k_article/k02`,
            });
          }
          this.evalfun(this.refreshpage)
          this.evalfun(this.getSingleGlobalResult)
          this.evalfun(this.warningerror)
          // this.$message({
          //   message: "删除草稿成功",
          //   type: "success",
          // });
          this.$message.success(this.$i18n.t("label.knowledgebase.delete.draft.succeeded"));
        });
      } else {
        //批量删除草稿
        let params = {
          ids: this.ids,
        };
        knowledgearticlesApi.batchRemoveDraft(params).then((res) => {
          if (res.data.failCount > 0) {
            // {a}条数据删除失败
            this.$message.error(
              res.data.failCount +
              this.$i18n.t("label.knowledgebase.data.deletion.failed", [
                res.data.failCount,
              ])
            );
          }
          if (res.data.succeedCount > 0) {
            setTimeout(() => {
              //  {a}条数据删除成功
              this.$message.success(
                res.data.succeedCount +
                this.$i18n.t(
                  "label.knowledgebase.data.deleted.successfully",
                  [res.data.succeedCount]
                )
              );
            }, 1000);
            this.evalfun(this.warningerror)
          }
          this.evalfun(this.refreshright)
        });
      }
    },
    //删除文章
    articlesure() {
      if (this.id) {
        let params = {
          id: this.id,
        };
        knowledgearticlesApi.removeArticle(params).then((res) => {
          this.evalfun(this.refreshpage)
          this.evalfun(this.getSingleGlobalResult)
          this.evalfun(this.warningerror)
          this.$message.success(
            this.$i18n.t("label.knowledgebase.delete.article.succeeded")
          );
        });
      } else {
        // 批量删除文章
        let params = {
          ids: this.ids,
        };
        knowledgearticlesApi.batchRemoveArticle(params).then((res) => {
          if (res.data.failCount > 0) {
            this.$message.error(
              res.data.failCount +
              this.$i18n.t("label.knowledgebase.data.deletion.failed", [
                res.data.failCount,
              ])
            );
          }
          if (res.data.succeedCount > 0) {
            setTimeout(() => {
              this.$message.success(
                res.data.succeedCount +
                this.$i18n.t(
                  "label.knowledgebase.data.deleted.successfully",
                  [res.data.succeedCount]
                )
              );
            }, 1000);
            this.evalfun(this.warningerror)
          }
          this.evalfun(this.refreshright)
        });
      }
    },
    // 归档
    filesure() {
      if (this.id) {
        let params = {
          articleId: this.id,
        };
        knowledgearticlesApi.articlePigeonhole(params).then((res) => {
          this.evalfun(this.refreshall)
          this.evalfun(this.refreshpage)
          this.evalfun(this.getSingleGlobalResult)
          if (res.returnCode == "1") {
            this.$message.success(this.$i18n.t("label.group.archived.success"));
          } else {
            this.$message.error(res.returnInfo);
          }
        });
      } else {
        let params = {
          ids: this.ids,
          prefix: "k02",
        };
        knowledgearticlesApi.batchArticlePigeonhole(params).then((res) => {
          if (res.data.failCount > 0) {
            // {a}条数据归档失败！
            this.$message.error(
              res.data.failCount +
              this.$i18n.t("label.knowledgebase.data.archiving.failed", [
                res.data.failCount,
              ])
            );
          }
          if (res.data.succeedCount > 0) {
            setTimeout(() => {
              // {a}条数据归档成功！
              this.$message.success(
                res.data.succeedCount +
                this.$i18n.t(
                  "label.knowledgebase.data.archived.successfully",
                  [res.data.succeedCount]
                )
              );
            }, 1000);
          }
          this.evalfun(this.refreshright)
        });
      }
    },
    // 还原
    recoverArchived() {
      if (this.id) {
        let params = {
          objectApi: "CloudccKArticle",
          id: this.id,
        };
        knowledgearticlesApi.recoverArchived(params).then((res) => {
          this.evalfun(this.refreshall)
          this.evalfun(this.refreshpage)
          this.evalfun(this.getSingleGlobalResult)
          // this.$message({
          //   message: "恢复成功",
          //   type: "success",
          // });
          this.$message.success(
            this.$i18n.t("label.knowledgebase.recovery.was.successful")
          );
        });
      } else {
        let params = {
          ids: this.ids,
        };
        knowledgearticlesApi.batchRecoverArchived(params).then((res) => {
          if (res.data.failCount > 0) {
            // {a}条数据恢复失败！
            this.$message.error(
              res.data.failCount +
              this.$i18n.t("label.knowledgebase.data.recovery.failed", [
                res.data.failCount,
              ])
            );
          }
          if (res.data.succeedCount > 0) {
            setTimeout(() => {
              // {a}条数据恢复成功！
              this.$message.success(
                res.data.succeedCount +
                this.$i18n.t(
                  "label.knowledgebase.data.recovery.successfully",
                  [res.data.succeedCount]
                )
              );
            }, 1000);
          }
          this.evalfun(this.refreshright)
        });
      }
    },
  }
}